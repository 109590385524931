<template>
  <v-dialog
    v-model="isOpen"
    max-width="90%"
    scrollable
    :width="width > 0 ? width : 'unset'"
    class="rounded-lg"
    v-on:click:outside="close"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot name="button"></slot>
      </div>
    </template>
    <v-card>
      <v-card-text class="pa-0">
        <v-icon v-on:click="close" class="pa-2" style="position: absolute"
          >mdi-close</v-icon
        >
        <div class="content-wrapper">
          <slot name="content"></slot>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseModal',
  data () {
    return {
      isOpen: false,
    }
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 0,
    },
  },
  mounted () {
    this.isOpen = this.dialog
  },
  methods: {
    close () {
      this.isOpen = false
      setTimeout(() => {
        this.$emit('close')
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.v-dialog {
  border-radius: 8px !important;
}
.content-wrapper {
  margin-top: 27px;
}
</style>
