import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'
import firebase from '../firebaseinit'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  // {
  //   path: '/g',
  //   name: 'BookingCheck',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../pages/BookingCheck.vue'),
  //   meta: { layout: DefaultLogin }
  // },
  // {
  //   path: '/g/:id',
  //   name: 'Workshop',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../pages/Group.vue'),
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },
  // {
  //   path: '/admin',
  //   name: 'Admin',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../pages/Admin.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/workshop',
  //   name: 'Workshop',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../pages/Workshop.vue'),
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },
  {
    path: '/faq',
    name: 'Faq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/Faq.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../pages/Login.vue'),
  //   meta: { layout: DefaultLogin },
  // },
  {
    path: '/terms',
    name: 'Terms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/Terms.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/Contact.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  // {
  //   path: '/sandbox',
  //   name: 'Sandbox',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../pages/Sandbox.vue'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !await firebase.getCurrentUser()) {
    next({ name: 'Login', params: { from: to.fullPath } })
  } else {
    next()
  }
})

export default router
