<template>
  <div class="wrapper">
    <div class="how-it-works" id="how-it-works">
      <!-- Originally named "How it works" -->
      <h2 style="margin: 16px 0 30px 0;">Three easy steps</h2>
      <div class="how-it-works">
        <BaseHowBlurb
          :number="1"
          title="What you'll learn"
          class="blurb-1-left"
          blurb="KindMusic is a beginner-friendly songwriting platform, complete with a hands-on workshop and your choice of music package. It also emphasizes emotional health, and helps turn feelings into music."
          :leftPercent="50"
        >
          <img
            src="@/assets/hiw1.svg"
            align="center"
            class="blurb-image go-up"
          />
        </BaseHowBlurb>
        <BaseHowBlurb
          :number="2"
          title="How it works"
          class="blurb-1-left"
          blurb="During the KindMusic workshop, you’ll learn songwriting technique by breaking down existing pieces of music. Next, you’ll use those skills to write your own lyrics, which will eventually be turned into a song. Finally, that song will be turned into: an acoustic recording, a full studio recording, and/or a music video."
          :leftPercent="40"
          :reverse="true"
        >
          <img
            src="@/assets/hiw2.svg"
            align="center"
            class="blurb-image"
          />
        </BaseHowBlurb>
        <BaseHowBlurb
          :number="3"
          title="Your song, delivered"
          class="blurb-1-left"
          blurb="You will receive your song 7 - 14 business days from the workshop date, and at the same time, you’ll help support an independent music artist! How would you like to receive your song? Select a music package below."
          :leftPercent="60"
        >
          <img
            src="@/assets/hiw3.svg"
            align="center"
            class="blurb-image"
          />
        </BaseHowBlurb>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHowBlurb from '@/components/base/BaseHowBlurb'
export default {
  components: {
    BaseHowBlurb,
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: #f4f7fd;
  .blurb-image {
    margin: auto;
    width: max-content;
    max-width: 270px;
  }
  .go-up {
    transform: translateY(-40px);
  }
}
.how-it-works {
  width: 869px;
  max-width: 90vw;
  margin: auto;
  padding: 5vw 0 0 0;
  text-align: center;
  h2 {
    font-size: calc(16px + 3vw);
    color: #30456D;
  }
}
</style>
