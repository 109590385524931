import firebase from '../../firebaseinit'

const state = () => ({
  songTitle: '',
  bandName: '',
  songUrl: '',
  videoUrl: '',
  posterUrl: '',
})

const actions = {
  init ({ commit }, id) {
    var docRef = firebase.firestore().collection('bookings').doc(id)

    docRef.get().then((doc) => {
      if (doc.exists) {
        // console.log('Document data:', doc.data())

        // Set poster url if exists.
        if (doc.data().posterUrl) {
          commit('setPosterUrl', doc.data().posterUrl)
        }

        // Set video url if exists.
        if (doc.data().videoUrl) {
          commit('setVideoUrl', doc.data().videoUrl)
        }

        // Set song url and data if exists.
        if (doc.data().songUrl && doc.data().bandName && doc.data().songTitle) {
          // create reference
          // var httpsReference = firebase.storage().refFromURL(doc.data().songUrl)

          // httpsReference.child('').getDownloadURL()
          //   .then((url) => {
          //     // `url` is the download URL for 'images/stars.jpg'

          //     // This can be downloaded directly:
          //     var xhr = new XMLHttpRequest()
          //     xhr.responseType = 'blob'
          //     xhr.onload = (event) => {
          //       var blob = xhr.response
          //       console.log(blob)
          //     }
          //     xhr.open('GET', url)
          //     xhr.send()

          //     // Or inserted into an <img> element
          //     var img = document.getElementById('myimg')
          //     img.setAttribute('src', url)
          //   })
          //   .catch((error) => {
          //     // Handle any errors
          //     console.log(error)
          //   })

          commit('setSongUrl', doc.data().songUrl)
          commit('setBandName', doc.data().bandName)
          commit('setSongTitle', doc.data().songTitle)
        }
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!')
      }
    }).catch((error) => {
      console.log('Error getting document:', error)
    })
  },
  // setImage ({ commit }, image) {
  //   commit('setImage', image)
  // },
  // setImageUrl ({ commit }, image) {
  //   commit('setImageUrl', image)
  // },
}

const mutations = {
  setPosterUrl (state, url) {
    state.posterUrl = url
  },
  setVideoUrl (state, url) {
    state.videoUrl = url
  },
  setSongUrl (state, url) {
    state.songUrl = url
  },
  setBandName (state, name) {
    state.bandName = name
  },
  setSongTitle (state, title) {
    state.songTitle = title
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
