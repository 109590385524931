<template>
  <div class="checkout-mobile-select-time">
    <div class="select-time-text-wrapper">
      <h2>Select Time</h2>
      <br/>
      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit exercitationem facere perspiciatis quod sequi commodi.</p>
    </div>
    <div class="select-time-wrapper">
      <CheckoutSelectTime width="100%" v-on:confirm="setTime($event)"/>
    </div>
  </div>
</template>

<script>
import CheckoutSelectTime from '@/components/CheckoutSelectTime'
export default {
  components: {
    CheckoutSelectTime,
  },
  methods: {
    setTime (time) {
      this.$store.dispatch('checkout/setTime', time)
    },
  },
}
</script>

<style lang="scss" scoped>
.checkout-mobile-select-time{
  margin: auto;
  .select-time-text-wrapper{
    margin: 20px 46px;
  }
  .select-time-wrapper{
    margin: 0 46px;
  }
}
</style>
