<template>
  <div>
    <BaseBanner />
    <HomeHowItWorks />
    <HomePackages />
    <!-- <HomeContactFaq /> -->
  </div>
</template>

<script>
import BaseBanner from '@/components/base/BaseBanner'
import HomeHowItWorks from '@/components/HomeHowItWorks'
import HomePackages from '@/components/HomePackages'
// import HomeContactFaq from '@/components/HomeContactFaq'
export default {
  components: {
    BaseBanner,
    HomeHowItWorks,
    HomePackages,
    // HomeContactFaq
  },
}
</script>

<style lang="scss" scoped>
</style>
