<template>
  <v-app id="inspire">
    <TheHeader />
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
    <TheFooter />
  </v-app>
</template>

<script>
import TheHeader from '@/components/TheHeader'
import TheFooter from '@/components/TheFooter'
export default {
  name: 'Default',
  components: {
    TheHeader,
    TheFooter,
  },
  data: () => ({
    drawer: null,
  }),
}
</script>

<style lang="scss">
.v-toolbar--prominent .v-toolbar__content {
  align-items: center !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
