const state = () => ({
  taxRate: 0.0825,
  isMobileCheckoutOpen: false,
  currentStep: 1,
  selectedPackage: '',
  date: '',
  time: '',
  organizerName: '',
  organizerEmail: '',
  groupName: '',
  groupGrade: '',
  agreed: false,
  steps: [
    'Schedule',
    'Time',
    'Your Info',
    'Payment',
  ],
  formValid: true,
  validationFlag: false,
  amounts: {
    Bronze: 299,
    Silver: 749,
    Gold: 999,
  },
})

const actions = {
  setTriggerValidation ({ commit }, value) {
    commit('setTriggerValidation', value)
  },
  setAgreed ({ commit }, value) {
    commit('setAgreed', value)
  },
  setFormValid ({ commit }, value) {
    commit('setFormValid', value)
  },
  setIsMobileCheckoutOpen ({ commit }, value) {
    commit('setIsMobileCheckoutOpen', value)
  },
  resetStep ({ commit }) {
    commit('resetStep')
  },
  incrementStep ({ commit, state }) {
    if (state.currentStep <= state.steps.length) {
      commit('incrementCurrentStep')
    }
  },
  decrementStep ({ commit, state }) {
    if (state.currentStep >= 2) {
      commit('decrementCurrentStep')
    }
  },
  setPackage ({ commit }, selectedPackage) {
    commit('setPackage', selectedPackage)
  },
  setDate ({ commit }, date) {
    return new Promise((resolve, reject) => {
      commit('setDate', date)
      resolve()
    })
  },
  setTime ({ commit }, time) {
    return new Promise((resolve, reject) => {
      commit('setTime', time)
      resolve()
    })
  },
  setOrganizerName ({ commit }, organizerName) {
    commit('setOrganizerName', organizerName)
  },
  setOrganizerEmail ({ commit }, organizerEmail) {
    commit('setOrganizerEmail', organizerEmail)
  },
  setGroupName ({ commit }, groupName) {
    commit('setGroupName', groupName)
  },
  setGroupGrade ({ commit }, groupGrade) {
    commit('setGroupGrade', groupGrade)
  },
}

const mutations = {
  resetStep (state) {
    state.currentStep = 1
  },
  setTriggerValidation (state, value) {
    state.validationFlag = !!(value)
  },
  setFormValid (state, value) {
    state.formValid = value
  },
  setIsMobileCheckoutOpen (state, value) {
    state.isMobileCheckoutOpen = !!(value)
  },
  setAgreed (state, value) {
    state.agreed = !!(value)
  },
  setPackage (state, value) {
    switch (value) {
      case 1:
      case 'Bronze':
        state.selectedPackage = 'Bronze'
        break
      case 2:
      case 'Silver':
        state.selectedPackage = 'Silver'
        break
      case 3:
      case 'Gold':
        state.selectedPackage = 'Gold'
        break
      default:
        state.selectedPackage = 'Gold'
        break
    }
  },
  setDate (state, date) {
    state.date = date
  },
  setTime (state, time) {
    state.time = time
  },
  setOrganizerName (state, name) {
    state.organizerName = name
  },
  setOrganizerEmail (state, email) {
    state.organizerEmail = email
  },
  setGroupName (state, group) {
    state.groupName = group
  },
  setGroupGrade (state, grade) {
    state.groupGrade = grade
  },
  incrementCurrentStep (state) {
    state.currentStep++
  },
  decrementCurrentStep (state) {
    state.currentStep--
  },
}

const getters = {
  getTaxString: state => {
    return formatDollar(state.taxRate * state.amounts[state.selectedPackage])
  },
  getSubtotalString: state => {
    return formatDollar(state.amounts[state.selectedPackage])
  },
  getTotalString: state => {
    return formatDollar(state.amounts[state.selectedPackage] + state.taxRate * state.amounts[state.selectedPackage])
  },
}

function formatDollar (num) {
  var p = num.toFixed(2).split('.')
  return ['$', p[0].split('').reverse().reduce(function (acc, num, i) {
    return num + (i && !(i % 3) ? ',' : '') + acc
  }, '.'), p[1]].join('')
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
