<template>
  <div class="checkout">
    <div class="checkout-left" :style="{ 'background-image': 'url(' + checkoutImage + ')' }">
      <BaseLogo :width="200" :fontOverride="20"
        >KindMusic<br />Workshop</BaseLogo
      >
      <div v-if="currentStep <= 3">
        <h3 class="caps">{{ selectedPackage }} package</h3>
        <p>{{ description }}</p>
      </div>
      <div v-else>
      <h3>Summary</h3>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>Name:</td>
              <td>{{organizerName}}</td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>{{organizerEmail}}</td>
            </tr>
            <tr>
              <td>NCL Chapter:</td>
              <td>{{groupName}}</td>
            </tr>
            <tr>
              <td>NCL Grade:</td>
              <td>{{groupGrade}}</td>
            </tr>
            <tr>
              <td>Package:</td>
              <td>{{selectedPackage}}</td>
            </tr>
            <tr>
              <td>Amount:</td>
              <td>{{subtotal}}</td>
            </tr>
            <tr>
              <td>Tax:</td>
              <td>{{taxString}}</td>
            </tr>
            <tr>
              <td>Total:</td>
              <td>{{total}}</td>
            </tr>
            <tr>
              <td>Date:</td>
              <td>{{readableDate}}</td>
            </tr>
            <tr>
              <td>Time:</td>
              <td>{{selectedTime}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </div>
    </div>

    <div class="center-right" v-if="currentStep == 1 || currentStep == 2">
      <div class="checkout-center" ref="center">
        <h3 class="center">Select a Date and Time</h3>
        <v-date-picker
          ref="picker"
          v-model="date"
          :allowed-dates="dates"
          min="1950-01-01"
          no-title
          class="date-picker"
          color="primary"
        ></v-date-picker>
        <p>Central Time - US &amp; Canada ({{ time }})</p>
      </div>
      <transition name="slide">
        <div class="checkout-right" v-if="currentStep == 2" ref="right">
          <CheckoutSelectTime v-on:confirm="processConfirm" width="200px"/>
        </div>
      </transition>
    </div>
    <CheckoutInfo v-if="currentStep == 3" :width="width + 'px'"/>
    <CheckoutBraintree
      v-if="currentStep == 4"
      :width="width + 'px'"
    />
  </div>
</template>

<script>
import BaseLogo from '@/components/base/BaseLogo'
import CheckoutSelectTime from '@/components/CheckoutSelectTime'
import CheckoutInfo from '@/components/CheckoutInfo'
import CheckoutBraintree from '@/components/CheckoutBraintree'
export default {
  name: 'CheckoutDesktop',
  components: {
    CheckoutBraintree,
    BaseLogo,
    CheckoutInfo,
    CheckoutSelectTime,
  },
  data () {
    return {
      date: '',
      width: '',
    }
  },
  props: {
    allowedDates: {
      type: Array,
      default: () => { return [] },
    },
    packages: {
      type: Array,
      default: () => { return [] },
    },
  },
  watch: {
    date (newValue) {
      this.$store.dispatch('checkout/setDate', newValue).then(() => {
        if (newValue && this.currentStep === 1) {
          this.$store.dispatch('checkout/incrementStep')
        }
      })
    },
  },
  computed: {
    currentStep () { return this.$store.state.checkout.currentStep },
    organizerName () { return this.$store.state.checkout.organizerName },
    organizerEmail () { return this.$store.state.checkout.organizerEmail },
    groupName () { return this.$store.state.checkout.groupName },
    groupGrade () { return this.$store.state.checkout.groupGrade },
    selectedPackage () { return this.$store.state.checkout.selectedPackage },
    // amount () { return { Bronze: '$299.00', Silver: '$749.00', Gold: '$999.00' }[this.selectedPackage] },
    checkoutImage () { return (this.currentStep <= 3) ? require('@/assets/checkout.svg') : 'none' },
    selectedTime () { return this.$store.state.checkout.time },
    taxString () { return this.$store.getters['checkout/getTaxString'] },
    subtotal () { return this.$store.getters['checkout/getSubtotalString'] },
    total () { return this.$store.getters['checkout/getTotalString'] },
    readableDate () {
      const date = new Date(this.date)

      // Readable Month
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ]
      const month = monthNames[date.getUTCMonth()]

      // Readable Day
      const day = this.getReadableDay(date.getUTCDate())

      return month + ' ' + day
    },
    time () {
      // create Date object for current location
      var d = new Date()
      var offset = '-6'
      // convert to msec
      // subtract local time zone offset
      // get UTC time in msec
      var utc = d.getTime() + d.getTimezoneOffset() * 60000

      // create new Date object for different city
      // using supplied offset
      var nd = new Date(utc + 3600000 * offset)
      var ampm = nd.getHours() > 11 ? 'PM' : 'AM'
      // return time as a string
      return (nd.getHours() % 12) + ':' + nd.getMinutes() + ' ' + ampm
    },
    description () {
      let theReturn
      this.packages.forEach((singlePackage) => {
        if (singlePackage.title === this.selectedPackage) {
          theReturn = singlePackage.description
        }
      })
      return theReturn
    },
  },
  methods: {
    getReadableDay (day) {
      switch (day) {
        case 1:
        case 21:
        case 31:
          return day + 'st'
        case 2:
        case 22:
          return day + 'nd'
        case 3:
        case 23:
          return day + 'rd'
        default:
          return day + 'th'
      }
    },
    progress () {
      if (this.currentStep < 4) {
        this.currentStep++
      }
    },
    regress () {
      if (this.currentStep >= 2) {
        this.currentStep--
      }
    },
    processConfirm () {
      this.getWidth()
      this.braintree = true
    },
    dates () {
      return this.allowedDates
    },
  },
  mounted () {
    if (this.currentStep === 2) {
      this.width = this.$refs.center.clientWidth + this.$refs.right.clientWidth
    }
  },
}
</script>

<style lang="scss" scoped>
.checkout {
  display: flex;
  justify-content: center;
  .checkout-left {

    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 55%;
    border-right: 1px solid lightgrey;
    padding: 60px 40px 200px 40px;
    p {
      max-width: 280px;
    }
    .caps {
      text-transform: uppercase;
    }
  }
  .center-right {
    display: flex;
  }
  .checkout-center {
    .date-picker {
      margin: auto;
      display: block;
    }
  }
  .checkout-center * {
    margin: 0 40px;
    &.v-picker {
      text-align: center;
      margin: 0 1px;
    }
  }
  .checkout-right {
    overflow: hidden;
    height: 555px;
    overflow-y: auto;
    padding: 30px;
  }
  h3 {
    font-size: 20px;
    color: black;
    font-weight: 500;
    line-height: 1;
    padding: 50px 0 28px 0;
    &.center {
      margin-right: 0;
    }
  }
}
//transitions
@keyframes slide-in {
  0% {
    transform: scaleX(0);
    transform-origin: 0% 50%;
    width: 0px;
    padding: 30px 0px;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 50%;
    width: auto;
    padding: 30px 30px;
  }
}

.slide-enter-active {
  animation: slide-in 0.5s;
}
.slide-leave-active {
  animation: slide-in 0.5s reverse;
}
</style>
