<template>
  <v-dialog
    v-model="isOpen"
    max-width="100%"
    min-height="100vh"
    scrollable
    width="100vw"
    class="rounded-lg dialog"
    v-on:click:outside="close"
    fullscreen
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot name="button"></slot>
      </div>
    </template>
    <div class="card-wrapper">
      <v-card class="card" color="#F3F2F7">
        <v-card-text class="pa-0">
          <!-- <div class="exit-wrapper">
            <v-icon v-on:click="close" class="pa-2" style=""
              >mdi-close</v-icon
            >
          </div> -->

          <div class="content-wrapper">
            <slot name="content"></slot>
          </div>
        </v-card-text>
      </v-card>
    </div>

  </v-dialog>
</template>

<script>
export default {
  name: 'BaseModalMobile',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 100,
    },
  },
  mounted () {
    this.isOpen = this.dialog
  },
  watch: {
    dialog (newValue, oldValue) {
      if (!newValue) {
        this.close()
      }
    },
  },
  methods: {
    close () {
      this.isOpen = false
      setTimeout(() => {
        this.$emit('close')
      }, 500)
    },
  },
  computed: {
    isOpen: {
      /* By default get() is used */
      get () {
        return this.$store.state.checkout.isMobileCheckoutOpen
      },
      /* We add a setter */
      set (value) {
        this.$store.dispatch('checkout/setIsMobileCheckoutOpen', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog {
  border-radius: 8px !important;
  margin: 0 0 0 0 !important;
  // max-height: 100% !important;
}
.content-wrapper {
  margin-top: 0px;
  padding-top: 50px;
  padding-bottom: 200px;
}
.card-wrapper{
  background-color: #30456D;
}
.exit-wrapper{
  width: 100%;
}
</style>
