<template>
  <div class="feature">
    <div class="icon-wrapper">
      <v-icon v-if="feature.active" color="accent darken-2">
        mdi-check-circle
      </v-icon>
      <v-icon v-else class="disabled-button"> mdi-close-circle-outline </v-icon>
    </div>
    <div>
      <div>
        <span class="name" :class="{ active: feature.active }">{{
          feature.name
        }}</span>
      </div>
      <div>
        <span class="subtext" v-if="feature.active">{{ feature.subtext }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Feature',
  props: {
    feature: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.feature {
  display: flex;
  text-align: left;
  margin-top: 20px;
  .name {
    font-weight: 500;
    color: lightgrey;
    font-size: 1.1em;
    &.active {
      color: black;
    }
  }
  .subtext {
    font-size: 0.8em;
    font-weight: 400;
    color: grey;
  }
  .icon-wrapper {
    margin-right: 30px;
  }
  .disabled-button {
    color: lightgrey;
  }
}
</style>
