<template>
  <div class="the-preloader">
    <svg class="flag" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 256 3">
      <desc>Flag of Germany</desc>
      <rect class="starting-color" width="256" height="3" y="0" x="0" fill="#000"/>
      <!-- <rect id="gold_stripe" width="2" height="3" y="0" x="0" fill="#FFCE00"/> -->
      <rect id="replacing-color" width="192" height="3" y="0" x="0" fill="#FFF"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'thePreloader',
  methods: {
    preloaderComplete () {
      this.$emit('completed')
    },
  },
  mounted () {
    var tl = this.$gsap.timeline()
    tl.set('#replacing-color', { x: -100 })
    tl.to('#replacing-color', { x: 1, duration: 3, ease: 'slow', onComplete: this.preloaderComplete }, '+=0.5')
    tl.to('.the-preloader', { scale: 2, opacity: 0, duration: 1, ease: 'power2.in' }, '-=1')
  },
}
</script>

<style lang="scss" scoped>
.the-preloader{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #572E7D;
  background-repeat: no-repeat;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .flag{
    width: 100vw;
    height: 100vh;
    mask-image: url('~@/assets/logo.svg');
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 50%;
    #replacing-color{
      // transform: translateX(-40%)
    }
  }
}
</style>
