<template>
  <div class="card-wrapper" :class="{ popular: info.mostPopular }">
    <div class="card" :class="{ popular: info.mostPopular }">
      <span v-if="info.mostPopular" class="pretext">MOST POPULAR</span>
      <h3 class="card-title">{{ info.title }}</h3>
      <span class="price">${{ info.price }}</span>
      <div class="includes">
        <HomeFeature
          v-for="(feature, index) in info.features"
          v-bind:key="index"
          :feature="feature"
        />
      </div>
      <component :is="currentModalComponent" :dialog="checkoutOpen"> // BaseModal or BaseModalMobile
        <template v-slot:button>
          <button
            class="button"
            :class="{ 'popular-button': info.mostPopular && !isHovered }"
            v-on:mouseover="$emit('hoveredOn')"
            v-on:mouseleave="$emit('hoveredOff')"
            v-on:click="openCheckout"
          >
            Start with {{ info.title }}
          </button>
        </template>
        <template v-slot:content>
          <Checkout :selected="info" :blocked-dates="blockedDates" v-on:closemodal="closeModal"/>
        </template>
      </component>
      <div>
        <BaseModal v-on:close="videoOpen = false" :width="700">
          <template v-slot:button>
            <button
              color="primary"
              class="example"
              dark
              v-on:click="videoOpen = true"
            >
              View an example
            </button>
          </template>
          <template v-slot:content>
            <v-card class="pa-4" elevation="0">
              <BaseVideo
                v-if="videoOpen"
                :src="require('@/assets/example.mp4')"
                :img="require('@/assets/example-thumbnail.svg')"
                :autoplay="true"
              />
            </v-card>
          </template>
        </BaseModal>
        <!-- <v-tooltip
      right
      max-width="300">
        <template v-slot:activator="{ on, attrs }">

        </template>
        <span>{{ info.description }}</span>
      </v-tooltip> -->
      </div>
    </div>
  </div>
</template>

<script>
import HomeFeature from '@/components/HomeFeature'
import BaseModal from '@/components/base/BaseModal'
import BaseModalMobile from '@/components/base/BaseModalMobile'
import Checkout from '@/components/Checkout'
import CheckoutMobile from '@/components/CheckoutMobile'
import BaseVideo from '@/components/base/BaseVideo'
export default {
  name: 'Card',
  components: {
    HomeFeature,
    BaseModal,
    Checkout,
    CheckoutMobile,
    BaseVideo,
    BaseModalMobile,
  },
  data () {
    return {
      videoOpen: true,
      blockedDates: [],
      checkoutOpen: false,
    }
  },
  mounted () {
    this.$axios
      .get('/getDates')
      .then((response) => {
        // handle success
        var blocked = []
        response.data.forEach((theDate) => {
          const date = new Date(theDate * 1000)
          // console.log(date.getFullYear() + '-' + this.zeroPad(date.getMonth()) + '-' + this.zeroPad(date.getDate()));
          blocked.push(
            date.getFullYear() +
              '-' +
              this.zeroPad(date.getMonth() + 1) +
              '-' +
              this.zeroPad(date.getDate())
          )
        })
        return blocked
      })
      .then((blockedDates) => {
        this.blockedDates = blockedDates
        this.datesLoaded = true
      })
      .catch((error) => {
        // handle error
        console.log(error)
        return error
      })
  },
  props: {
    info: Object,
    isHovered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentModalComponent () {
      return (this.$vuetify.breakpoint.mdAndUp) ? 'BaseModal' : 'BaseModalMobile'
    },
  },
  methods: {
    zeroPad (num) {
      return Number(num) < 10 ? '0' + num : '' + num
    },
    closeModal () {
      this.checkoutOpen = false
    },
    openCheckout () {
      this.$store.dispatch('checkout/resetStep')
      this.$store.dispatch('checkout/setPackage', this.info.title)
      this.checkoutOpen = true
    },
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  display: flex;
  justify-content: flex-end;
  border-radius: 15px;
  margin: 8px;
  box-shadow: 0px 0px 5px #6f6f6f;
  text-align: center;
  &.popular {
    background-color: #30456D;
    padding: 5px 0 0 0;
  }
}
.card {
  background-color: white;
  border-radius: 15px;
  // height: 400px;
  width: 320px;
  padding: 36px 36px 20px 36px;
  &.popular {
    padding-top: 16px;
  }
}
.card-title {
  font-size: 2em;
  margin-top: 25px;
  line-height: 1.4;
}
.price {
  overflow: hidden;
  text-align: center;
  color: #30456D;
  font-weight: 600;
}
.price:before,
.price:after {
  background-color: lightgrey;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 30%;
}
.price:before {
  right: 0.5em;
  margin-left: -30%;
}
.price:after {
  left: 0.5em;
  margin-right: -30%;
}
.pretext {
  color: #30456D;
  font-size: 0.9em;
  font-weight: 500;
}
.includes {
  margin-bottom: 30px;
}
.button {
  border: 2px solid #30456D;
  background-color: transparent;
  color: #30456D;
  width: 100%;
  margin: 30px 0 14px 0;
  padding-top: 8px;
  padding-bottom: 8px;
  transition: border 0.5s ease-in-out, color 0.5s ease-in-out,
    background-color 0.5s ease-in-out;
  &:hover {
    background-color: black;
    color: white;
    border: 2px solid black;
  }
  &.popular-button {
    background-color: black;
    color: white;
    border: 2px solid black;
    &:hover {
      border: 2px solid #30456D;
      background-color: transparent;
      color: #30456D;
    }
  }
}
.more {
  font-size: 0.8em;
  font-weight: 400;
  color: grey;
}
.example {
  font-size: 0.8em;
}
</style>
