<template>
  <div class="timeline">
    <div>
      <v-btn icon class="timeline-button">
        <v-icon :color="completedColor" size="18">{{ getContent(1) }}</v-icon>
        <div class="timeline-text">schedule</div>
      </v-btn>
    </div>
    <div v-for="(step, index) in stepsSliced" :key="index">
      <div class="spacer" :style="{ borderColor: getColor(index + 2) }"></div>
      <v-btn icon class="timeline-button">
        <v-icon :color="getColor(index + 2)" size="18">{{
          getContent(index + 2)
        }}</v-icon>
        <div class="timeline-text">{{ step }}</div>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTimeline',
  props: {
    completedColor: {
      type: String,
      default: '#30456D',
    },
    currentColor: {
      type: String,
      default: '#30456D',
    },
    incompletedColor: {
      type: String,
      default: '#C8C8CA',
    },
    checkoutSteps: {
      type: Array,
      default: () => { return [] },
    },
  },
  methods: {
    getColor (index) {
      let theReturn
      if (this.active === index) {
        theReturn = this.currentColor
      } else if (this.active > index) {
        theReturn = this.completedColor
      } else {
        theReturn = this.incompleteColor
      }
      return theReturn
    },
    getContent (index) {
      let theReturn
      if (this.active > index) {
        theReturn = 'mdi-check-circle'
      } else {
        theReturn = 'mdi-numeric-' + index + '-circle'
      }
      return theReturn
    },
  },
  computed: {
    steps () { return this.$store.state.checkout.steps },
    active () { return this.$store.state.checkout.currentStep },
    stepsSliced () { return this.steps.slice(1) },
  },
}
</script>

<style lang="scss">
  .v-btn__content{
    flex-flow: column !important;
  }
</style>

<style lang="scss" scoped>
.timeline {
  display: flex;
  justify-content: center;
  margin-top: 7px;
  .timeline-button {
    margin-top: 16px;
    z-index: 10;
  }
  .spacer {
    display: inline-block;
    height: 0;
    width: 63px;
    margin: 0 -11px;
    border: 1px solid grey;
    z-index: 0;
  }
  .timeline-text{
    font-size: .7em;
    text-transform: capitalize;
  }
}
</style>
