<template>
  <div class="timeButton" :style="'width: ' + width + ';'">
    <button class="time" v-on:click="sendUp" :class="{ active: selected }">
      {{ time }}
    </button>
    <transition name="slide">
      <button class="confirm" v-if="selected" v-on:click="sendConfirm">
        Confirm
      </button>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '1',
    },
  },
  methods: {
    sendUp () {
      this.$emit('select')
    },
    sendConfirm () {
      this.$emit('confirm')
    },
  },
}
</script>

<style lang="scss" scoped>
.timeButton {
  display: flex;
  width: 200px;
  button {
    border: 1px solid grey;
    border-radius: 4px;
    margin: 3px;
    width: 100%;
    padding: 10px 0;
    &.active {
      background-color: #80848b;
      color: white;
    }
    &.confirm {
      background-color: #595bdb;
      color: white;
    }
  }
}
@keyframes slide-in {
  0% {
    transform: translate(120%);
  }
  100% {
    transform: translate(0%);
  }
}
.slide-enter-active {
  animation: slide-in 0.5s;
}
.slide-leave-active {
  animation: slide-in 0.5s reverse;
}
</style>
