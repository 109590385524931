<template>
  <div
    class="packages"
    :class="{ mobile: $vuetify.breakpoint.smAndDown }"
    id="packages"
  >
    <HomePackagesMobile v-if="$vuetify.breakpoint.smAndDown" :cards="cards" />
    <HomePackagesDesktop v-else :cards="cards" :hovered="buttonHovered" />
  </div>
</template>

<script>
import HomePackagesDesktop from '@/components/HomePackagesDesktop'
import HomePackagesMobile from '@/components/HomePackagesMobile'
import packages from '@/json/packages.json'
export default {
  name: 'Packages',
  components: {
    // HomeCard,
    // KinesisContainer,
    // KinesisElement,
    HomePackagesDesktop,
    HomePackagesMobile,
  },
  data () {
    return {
      buttonHovered: false,
      cards: packages,
    }
  },
}
</script>

<style lang="scss" scoped>
.packages {
  background-color: #f4f7fd;
  background-image: url("~@/assets/diagonal.svg");
  background-size: 100%;
  background-position: center;
  padding-bottom: 100px;
  &.mobile {
    background-position: center bottom;
  }
  flex-wrap: nowrap;
  .packages-inner {
    width: 1024px;
    max-width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    > div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
</style>
