<template>
  <component
    :is="($vuetify.breakpoint.mdAndUp)?'CheckoutDesktop':'CheckoutMobile'"
    :packages="packages"
  />
</template>

<script>
import CheckoutDesktop from '@/components/CheckoutDesktop'
import CheckoutMobile from '@/components/CheckoutMobile'
import Packages from '@/json/packages.json'
export default {
  name: 'Checkout',
  components: {
    CheckoutDesktop,
    CheckoutMobile,
  },
  data () {
    return {
      packages: Packages,
    }
  },
  props: {
    selected: {
      type: Object,
      default: () => {
        return {}
      },
    },
    blockedDates: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    time () {
      // create Date object for current location
      var d = new Date()
      var offset = '-6'
      // convert to msec
      // subtract local time zone offset
      // get UTC time in msec
      var utc = d.getTime() + d.getTimezoneOffset() * 60000

      // create new Date object for different city
      // using supplied offset
      var nd = new Date(utc + 3600000 * offset)
      var ampm = nd.getHours() > 11 ? 'PM' : 'AM'
      // return time as a string
      return (nd.getHours() % 12) + ':' + nd.getMinutes() + ' ' + ampm
    },
  },
  methods: {
    setFormValidState (valid) {
      this.isFormValid = valid
    },
    setTime (time) {
      this.selectedOptions.time = time
    },
    setDate (date) {
      this.selectedOptions.date = date
    },
    allowedDates (val) {
      var theReturn
      var today = new Date()
      var date = new Date(val)
      if (
        today.getFullYear() === date.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate()
      ) {
        theReturn = this.blockedDates.indexOf(val) < 0
      } else {
        theReturn = today < date && this.blockedDates.indexOf(val) < 0
      }
      this.allowedDatesArray = theReturn
      // return theReturn
    },
    zeroPad (num) {
      return Number(num) < 10 ? '0' + num : '' + num
    },
    closemodal () {
      this.$emit('closemodal')
    },
  },
  mounted () {
    console.log((this.$vuetify.breakpoint.mdAndUp) ? 'CheckoutDesktop' : 'CheckoutMobile')
  },
}
</script>

<style lang="scss" scoped>

</style>
