<template>
  <div class="footer" :class="{ 'mobile-small': $vuetify.breakpoint.xs }">
    <div class="footer-left">
      <div class="copyright">Copyright &copy; {{ year }} KindMusic</div>
      <div class="links">
        <span id="dotOne" class="hidden-sm-and-down">&bull;</span>
        <router-link to="/terms">Terms</router-link>
        <span id="dotTwo">&bull;</span>
        <router-link to="/terms">Privacy</router-link>
        <span id="dotTwo">&bull;</span>
        <router-link to="/faq">FAQ</router-link>
        <span id="dotTwo">&bull;</span>
        <router-link to="/contact">Contact</router-link>
      </div>
    </div>
    <div class="footer-right hidden-sm-and-down">
      <ul>
        <li>
          <a><v-icon color="white">mdi-facebook</v-icon></a>
        </li>
        <li>
          <a><v-icon color="white">mdi-twitter</v-icon></a>
        </li>
        <li>
          <a><v-icon color="white">mdi-linkedin</v-icon></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheFooter',
  computed: {
    year () {
      return new Date().getFullYear()
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  max-width: 100vw;
  overflow: hidden;
  justify-content: space-between;
  background-color: #1e204d;
  padding: 20px;
  font-size: .95em;
  .footer-left {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 5%;
    .copyright {
      text-align: center;
      color: white;
      white-space: nowrap;
    }
    .links {
      white-space: nowrap;
      a {
        color: white;
      }
      span {
        display: inline-block;
        text-align: center;
        color: white;
        width: 19px;
      }
    }
  }
  &.mobile-small {
    .footer-left {
      flex-wrap: wrap;
      justify-content: left;
      .copyright{
        text-align: left;
        padding-right: 40px;
      }
    }
  }
  .footer-right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5%;
    height: 100%;
    ul {
      display: inline-flex;
      //comment
      li {
        color: white;
        list-style: none;
        margin: 0 5px 0 5px;
      }
    }
  }
}
</style>
