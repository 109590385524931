var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"banner",staticClass:"banner",class:{
    'mobile-medium': _vm.$vuetify.breakpoint.md,
    'mobile-small': _vm.$vuetify.breakpoint.smAndDown,
  },style:({ backgroundPosition: _vm.parallaxData, backgroundSize: 'auto calc(200vh - 70px)'})},[_c('div',{staticClass:"banner-inner",class:{
      'mobile-medium': _vm.$vuetify.breakpoint.md,
      'mobile-small': _vm.$vuetify.breakpoint.smAndDown,
    }},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"banner-left",class:{ 'mobile' : _vm.$vuetify.breakpoint.smAndDown }},[_vm._t("title",[_c('h1',{staticClass:"welcome",class:{'mobile':_vm.$vuetify.breakpoint.smAndDown}},[_vm._v(" Welcome to"),_c('br'),_vm._v(" KindMusic"),_c('br'),_vm._v("(NCL Version) ")])]),_vm._t("subtitle",[_c('div',{staticClass:"title-two"},[_vm._v(" Write a song, help an artist. ")])]),_vm._t("actions",[_c('div',{staticClass:"banner-buttons",class:{
              'mobile-medium': _vm.$vuetify.breakpoint.md,
              'mobile-small': _vm.$vuetify.breakpoint.smAndDown,
            }},[_c('button',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#how-it-works'),expression:"'#how-it-works'"}],staticClass:"button alternative",on:{"mouseover":_vm.mouseOver,"mouseout":_vm.mouseOut}},[_vm._v(" How it Works ")]),_c('button',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:('#packages'),expression:"'#packages'"}],staticClass:"button",on:{"mouseover":_vm.mouseOver,"mouseout":_vm.mouseOut}},[_vm._v(" Get Started ")])])])],2)]),_c('div',{staticClass:"people-wrapper"},[_c('img',{attrs:{"src":require('@/assets/people-min.png'),"alt":""}})])]),(_vm.chevron)?_c('div',{staticClass:"chevron"},[_c('v-icon',{attrs:{"x-large":"","color":"white"}},[_vm._v(" mdi-chevron-double-down ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }