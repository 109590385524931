const state = () => ({
  image: '',
  imageUrl: '',
})

const actions = {
  setImage ({ commit }, image) {
    commit('setImage', image)
  },
  setImageUrl ({ commit }, image) {
    commit('setImageUrl', image)
  },
}

const mutations = {
  setImage (state, image) {
    state.image = image
  },
  setImageUrl (state, imageUrl) {
    state.imageUrl = imageUrl
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
