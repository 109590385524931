<template>
  <div class="logo-wrapper">
    <v-img
      :max-width="width"
      src="@/assets/logo.svg"
      align="center"
      class="float-left"
    ></v-img>
  </div>
</template>

<script>
export default {
  name: 'BaseLogo',
  props: {
    width: {
      type: Number,
      default: 170,
    },
    fontOverride: {
      type: Number,
      default: 19,
    },
    fontWeight: {
      type: Number,
      default: 500,
    },
  },
  computed: {
    fontSize () {
      var theReturn
      if (this.fontOverride > 0) {
        theReturn = this.fontOverride
      } else {
        theReturn = this.width / 2.5
      }
      return theReturn
    },
  },
}
</script>

<style lang="scss" scoped>
.logo-wrapper {
  display: flex;
  align-items: center;
}
.logo-text {
  color: black;
  font-weight: 400;
  margin-left: 15px;
  line-height: 1;
}
</style>
