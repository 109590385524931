<template>
  <div class="header">
    <div class="desktop-header" :class="$route.name">
      <div class="desktop-header-inner">
        <router-link to="/">
          <BaseLogo />
        </router-link>
        <div class="nav-wrapper" style="display: flex;">
          <!-- <router-link v-if="!user" class="nav-item" to="/login"
            >login</router-link
          > -->
          <div v-if="user">
            <button class="nav-item button" v-on:click="logout">logout</button>
            <span v-if="$route.name != 'Workshop'" class="hidden-md-and-down">|</span>
          </div>
          <button
            v-if="$route.name != 'Workshop'"
            class="nav-item button hidden-md-and-down"
            v-scroll-to="'#packages'"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
    <div class="mobile-header hidden-md-and-up"></div>
  </div>
</template>

<script>
import BaseLogo from '@/components/base/BaseLogo'
export default {
  name: 'TheHeader',
  components: {
    BaseLogo,
  },
  data () {
    return {
      user: null,
      drawer: false,
    }
  },
  created () {
    this.$firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user
      } else {
        this.user = null
      }
    })
  },
  methods: {
    logout () {
      this.$firebase
        .auth()
        .signOut()
        .then(() => {
          this.$firebase.auth().onAuthStateChanged(() => {
            this.$router.push('/')
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.desktop-header {
  display: flex;
  align-items: center;
  height: 70px;
  &.Workshop {
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  }
  .desktop-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95vw;
    max-width: 95vw;
    margin: auto;
  }
  .nav-wrapper {
    color: #545bd6;
  }
  .nav-item {
    text-decoration: none;
    margin: 0 2vw;
    &:last-child {
      margin-right: 0;
    }
  }
}
.float-left {
  float: left;
}
</style>
