<template>
  <div
    class="banner"
    ref="banner"
    :class="{
      'mobile-medium': $vuetify.breakpoint.md,
      'mobile-small': $vuetify.breakpoint.smAndDown,
    }"
    :style="{ backgroundPosition: parallaxData, backgroundSize: 'auto calc(200vh - 70px)'}"
  >
    <div
      class="banner-inner"
      :class="{
        'mobile-medium': $vuetify.breakpoint.md,
        'mobile-small': $vuetify.breakpoint.smAndDown,
      }"
    >
      <div style="display:flex; align-items:center;">
        <div class="banner-left" :class="{ 'mobile' : $vuetify.breakpoint.smAndDown }">
          <slot name="title">
            <h1 class="welcome" :class="{'mobile':$vuetify.breakpoint.smAndDown}">
              Welcome to<br />
              KindMusic<br />(NCL Version)
            </h1>
          </slot>
          <slot name="subtitle">
            <div class="title-two">
              Write a song, help an artist.
            </div>
          </slot>
          <slot name="actions">
            <div
              class="banner-buttons"
              :class="{
                'mobile-medium': $vuetify.breakpoint.md,
                'mobile-small': $vuetify.breakpoint.smAndDown,
              }"
            >
              <button class="button alternative" v-scroll-to="'#how-it-works'" v-on:mouseover="mouseOver" v-on:mouseout="mouseOut">
                How it Works
              </button>
              <button class="button" v-scroll-to="'#packages'" v-on:mouseover="mouseOver" v-on:mouseout="mouseOut">
                Get Started
              </button>
            </div>
          </slot>
        </div>
      </div>
      <!-- the people in foreground-->
      <div class="people-wrapper">
        <img :src="require('@/assets/people-min.png')" alt="" />
      </div>
      <!-- <div class="foreground-image" :class="{'mobile-medium' : $vuetify.breakpoint.md,
                                              'mobile-small' : $vuetify.breakpoint.smAndDown}">
      </div>  -->

    </div>
    <div v-if="chevron" class="chevron">
      <v-icon x-large color="white">
        mdi-chevron-double-down
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      myVar: 'banner',
      parallaxData: 0,
    }
  },
  props: {
    chevron: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleScroll (event) {
      // Any code to be executed when the window is scrolled
      this.parallaxData = '0 ' + (-0.5 * window.pageYOffset) + 'px'
    },
    mouseOver (e) {
      this.$gsap.to(e.target, { scale: 1.1, duration: 0.5 })
    },
    mouseOut (e) {
      this.$gsap.to(e.target, { scale: 1, duration: 0.5 })
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    this.handleScroll()
    var tl = this.$gsap.timeline()
    setTimeout(() => {
      tl.to('.welcome', { y: 0, opacity: 1, duration: 1.5, ease: 'ease' })
      tl.to('.title-two', { y: 0, opacity: 1, duration: 1.5, ease: 'power3.out' }, '-=0.75')
      tl.to('.banner-buttons', { y: 0, opacity: 1, duration: 2, ease: 'power2.out' }, '-=1')
    }, 3500)

    // console.log(tl)
  },
}
</script>

<style lang="scss" scoped>

.banner {
  background-image: url("~@/assets/background.jpg");
  background-position: center;
  background-size: 300%;
  position: relative;
  // display: flex;
  // justify-content: left;
  text-align: center;
  min-height: calc(100vh - 70px);
  color: white;
  // &.mobile-medium {
  //   // min-height: 100vh;
  // }
  .chevron{
    position:absolute;
    bottom: 20px;
    width: 100%;
    .v-icon{
      transform: scaleX(1.5);
      margin: auto;
    }
  }
  &.mobile-small {
    background-position: center;
    justify-content: center;
    // min-height: 100vh;
    // background-image: url("~@/assets/background.jpg");
  }
  .banner-inner {
    display: grid;
    min-height: calc(100vh - 70px);
    grid-template-columns: 1fr 1fr;
    // &.mobile-medium {
    //   min-height: 100vh;
    //   // grid-template-columns: 1fr;
    // }
    &.mobile-small {
      min-height: calc(100vh - 70px);
      grid-template-columns: 1fr;
    }
    align-items: bottom;
    .banner-left {
      font-size: calc(14px + 0.8vw);
      // margin: 150px calc(10px + 10vw);
      // max-width: 490px;
      margin: calc(20px + 5vw) auto;
      &.mobile{
        margin-bottom: 0;
      }
      .welcome{
        transform: translateY(300px);
        opacity: 0;
      }
      h1 {
        font-weight: 500;
        // margin-top: calc(35px + 3vw);
        line-height: 1.4;
        &.mobile{
          margin: 0;
        }
      }
      .title-two {
        font-weight: 300;
        font-size: calc(10px + 0.8vw);
        padding-top: 2vw;
        transform: translateY(300px);
        opacity: 0;
      }
      .banner-buttons {
        padding-top: 2vw;
        transform: translateY(300px);
        opacity: 0;
        button {
          background-color: #3BAECC;
          font-size: calc(8px + 0.5vw);
          border: 1px solid #3BAECC;
          padding: 6px 50px;
          border-radius: 5px;
          margin: 5px 10px;
          &.alternative {
            background-color: transparent;
            border: 1px solid white;
          }
        }
        &.mobile-medium {
          > button {
            display: block;
            margin: 10px auto;
            width: 190px;
          }
        }
        &.mobile-small {
          > button {
            display: block;
            margin: 10px auto;
            // width: 70vw;
            font-size: calc(8px + 2vw);
          }
        }
      }
    }
    .people-wrapper {
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      img {
        height: auto;
        width: 90%;
      }
    }
  }
}
.up-enter-active {
  animation: up 2s cubic-bezier(0.08, 0.49, 0, 0.99);
}
.up-leave-active {
  animation: up 2s cubic-bezier(0.08, 0.49, 0, 0.99) reverse;
}
@keyframes up {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
