import firebase from '../../firebaseinit'
const state = () => ({
  groups: [],
  responses: [],
  csv: '',
  image: null,
  imageUrl: null,
  video: null,
  videoUrl: null,
  song: null,
  songUrl: null,
  screeshot: null,
  screeshotUrl: null,
  loading: {
    songUploading: false,
    posterUploading: false,
    videoUploading: false,
  },
})

const actions = {
  setImage ({ commit }, image) {
    commit('setImage', image)
  },
  setImageUrl ({ commit }, image) {
    commit('setImageUrl', image)
  },
  setScreenshot ({ commit }, image) {
    commit('setScreenshot', image)
  },
  setScreenshotUrl ({ commit }, image) {
    commit('setScreenshotUrl', image)
  },
  setVideo ({ commit }, video) {
    commit('setVideo', video)
  },
  setVideoUrl ({ commit }, video) {
    commit('setVideoUrl', video)
  },
  setSong ({ commit }, song) {
    commit('setSong', song)
  },
  setSongUrl ({ commit }, song) {
    commit('setSongUrl', song)
  },
  setLoading ({ commit }, info) {
    switch (info.name) {
      case 'song':
        commit('setSongLoading', info.value)
        break
      case 'video':
        commit('setVideoLoading', info.value)
        break
      case 'poster':
        commit('setPosterLoading', info.value)
        break

      default:
        break
    }
  },
  loadResponses ({ commit }, id) {
    firebase
      .firestore()
      .collection('submissions')
      .where('group', '==', id)
      .get()
      .then((snapshot) => {
        const responses = []
        if (snapshot.exists) {
          snapshot.forEach((docs) => {
            responses.push({
              user: docs.data().user,
              band: docs.data().collected['Band Name'],
              topic: docs.data().collected['Song Topic'],
              phrase: docs.data().collected['Catch Phrase'],
              lyrics: docs.data().collected.Lyrics,
              image: docs.data().imageUrl,
            })
          })
          commit('setResponses', responses)
          return responses
        } else {
          return false
        }
      })
      .then((responses) => {
        if (responses) {
          const csvStart = Object.keys(responses[0]).join(',') + '\n'
          let csv = ''
          console.log(csvStart)

          responses.forEach(function (row) {
            console.log(Object.values(row))
            csv += Object.values(row).join(',')
            csv += '\n'
          })

          commit('setCsv', 'data:text/csv;charset=utf-8,' + encodeURI(csvStart + csv))
        }
        // this.downloadAllCsv = 'data:text/csv;charset=utf-8,' + encodeURI(csvStart + csv)
      })
      .catch((error) => {
        console.log('...' + error)
      })
  },
  uploadSong ({ commit, state }, { id, bandName, songTitle }) {
    console.log(id)
    console.log(bandName)
    console.log(songTitle)
    const key = id
    const filename = state.song.name
    const ext = filename.slice(filename.lastIndexOf('.'))
    firebase.storage().ref('uploaded-songs/' + key + ext).put(state.song).then((fileData) => {
      fileData.ref.getDownloadURL().then(url => {
        console.log(' * new url', url)
        firebase.firestore().collection('bookings').doc(key).update({
          songUrl: url,
          bandName: bandName,
          songTitle: songTitle,
        }).then(() => {
          // console.log(success)
          commit('setSongLoading', false)
          console.log('Song uploaded.')
          // this.$emit('congrats')
        })
      })
    })
      .catch(function (error) {
        console.error('Error adding document: ', error)
      })
  },
  uploadImage ({ commit, state }, key) {
    const filename = state.image.name
    const ext = filename.slice(filename.lastIndexOf('.'))
    firebase.storage().ref('uploaded-posters/' + key + ext).put(state.image).then((fileData) => {
      fileData.ref.getDownloadURL().then(url => {
        console.log(' * new url', url)
        firebase.firestore().collection('bookings').doc(key).update({ posterUrl: url }).then(() => {
          // console.log(success)
          commit('setPosterLoading', false)
          console.log('done')
          // this.$emit('congrats')
        })
      })
    })
      .catch(function (error) {
        console.error('Error adding document: ', error)
      })
  },
  uploadVideo ({ commit, state }, key) {
    const filename = state.video.name
    const ext = filename.slice(filename.lastIndexOf('.'))
    firebase.storage().ref('uploaded-videos/' + key + ext).put(state.video).then((fileData) => {
      fileData.ref.getDownloadURL().then(url => {
        console.log(' * new url', url)
        firebase.firestore().collection('bookings').doc(key).update({ videoUrl: url }).then(() => {
          // console.log(success)

          console.log('done')
          return true
          // this.$emit('congrats')
        })
      })
    })
      .then((success) => {
        const screenshotFilename = state.screenshot.name
        const screenshotExt = screenshotFilename.slice(screenshotFilename.lastIndexOf('.'))
        firebase.storage().ref('uploaded-screenshots/' + key + screenshotExt).put(state.screenshot).then((fileData) => {
          fileData.ref.getDownloadURL().then(url => {
            console.log(' * new url', url)
            firebase.firestore().collection('bookings').doc(key).update({ screenshotUrl: url }).then(() => {
              // console.log(success)
              commit('setVideoLoading', false)
              console.log('done')
              // this.$emit('congrats')
            })
          })
        })
          .catch(function (error) {
            console.error('Error adding screenshot: ', error)
          })
      })
      .catch(function (error) {
        console.error('Error adding video: ', error)
      })
  },
}

const mutations = {
  setResponses (state, responses) {
    state.responses = responses
  },
  setCsv (state, csv) {
    state.csv = csv
  },
  setImageUrl (state, imageUrl) {
    state.imageUrl = imageUrl
  },
  setImage (state, image) {
    state.image = image
  },
  setScreenshotUrl (state, screenshotUrl) {
    state.screenshotUrl = screenshotUrl
  },
  setScreenshot (state, screenshot) {
    state.screenshot = screenshot
  },
  setVideoUrl (state, videoUrl) {
    state.videoUrl = videoUrl
  },
  setVideo (state, video) {
    state.video = video
  },
  setSongUrl (state, songUrl) {
    state.songUrl = songUrl
  },
  setSong (state, song) {
    state.song = song
  },
  setSongLoading (state, status) {
    state.loading.songUploading = status
  },
  setVideoLoading (state, status) {
    state.loading.videoUploading = status
  },
  setPosterLoading (state, status) {
    state.loading.posterUploading = status
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
