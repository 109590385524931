<template>
  <div id="app">
    <thePreloader v-if="loading" v-on:completed="loading = false"/>
    <!-- <thePreloader /> -->
    <component :is="this.$route.meta.layout || 'default'" />
  </div>
</template>

<script>
import Default from '@/layouts/Default'
import DefaultLogin from '@/layouts/DefaultLogin'
import thePreloader from '@/components/base/thePreloader'
export default {
  components: {
    Default,
    DefaultLogin,
    thePreloader,
  },
  data () {
    return {
      loading: true,
    }
  },
  mounted () {
    window.AudioContext =
      window.AudioContext ||
      window.webkitAudioContext ||
      window.mozAudioContext ||
      window.msAudioContext
  },
  metaInfo: {
    // Children can override the title.
    title: 'KindMusic',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s | Write a song, help an artist.',
    // Define meta tags here.
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'Welcome to KindMusic | KindMusic is a beginner-friendly songwriting platform, complete with a hands on workshop and your choice of music delivery package.' },
      // OpenGraph data (Most widely used)
      { property: 'og:title', content: 'KindMusic | Write a song, help an artist.' },
      { property: 'og:site_name', content: 'KindMusic' },
      // The list of types is available here: http://ogp.me/#types
      { property: 'og:type', content: 'website' },
      // Should the the same as your canonical link, see below.
      { property: 'og:url', content: 'https://kindmusic.org' },
      { property: 'og:image', content: 'https://kindmusic.org/share.jpeg' },
      // Often the same as your meta description, but not always.
      { property: 'og:description', content: 'Welcome to KindMusic | KindMusic is a beginner-friendly songwriting platform, complete with a hands on workshop and your choice of music delivery package.' },

      // Twitter card
      { name: 'twitter:card', content: 'KindMusic is a beginner-friendly songwriting platform.' },
      { name: 'twitter:site', content: 'https://kindmusic.org' },
      { name: 'twitter:title', content: 'KindMusic | Write a song, help an artist.' },
      { name: 'twitter:description', content: 'Welcome to KindMusic | KindMusic is a beginner-friendly songwriting platform, complete with a hands on workshop and your choice of music delivery package.' },
      // Your twitter handle, if you have one.
      // { name: 'twitter:creator', content: '@alligatorio' },
      { name: 'twitter:image:src', content: 'https://kindmusic.org/share.jpeg' },

      // Google / Schema.org markup:
      { itemprop: 'name', content: 'KindMusic | Write a song, help an artist.' },
      { itemprop: 'description', content: 'Welcome to KindMusic | KindMusic is a beginner-friendly songwriting platform, complete with a hands on workshop and your choice of music delivery package.' },
      { itemprop: 'image', content: 'https://kindmusic.org/share.jpeg' },
    ],
  },
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");
body {
  font-family: roboto;
  overflow: hidden;
  .button {
    background-color: #3BAECC;
    color: white;
    padding: 4px 30px;
    border-radius: 5px;
    transition: opacity 0.3s ease-in-out;
    text-decoration: none;
  }
  a {
    text-decoration: none;
  }
}
</style>
