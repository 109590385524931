<template>
  <div class="checkout-mobile-schedule">
    <div class="schedule-text-wrapper">
      <h2>Schedule</h2>
      <br/>
      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit exercitationem facere perspiciatis quod sequi commodi.</p>
    </div>
    <v-card class="picker-wrapper-card" align="center" justify="center" width="290">
      <v-date-picker
        ref="picker"
        v-model="date"
        min="1950-01-01"
        no-title
        class="date-picker"
        color="primary"
      ></v-date-picker>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'CheckoutMobileSchedule',
  data () {
    return {
      date: '',
    }
  },
  methods: {
    setDate (date) {
      this.$store.dispatch('checkout/setDate', date)
    },
  },
  watch: {
    date (newValue, oldValue) {
      console.log(newValue)
      this.setDate(newValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.checkout-mobile-schedule{
  margin: auto;
  .schedule-text-wrapper{
    margin: 20px 46px;
  }
  .picker-wrapper-card{
    margin: auto;
    border-radius: 15px;
    padding: 20px 0;
  }
  .date-picker {
    margin: auto;
  }
}

</style>
