<template>
  <video-player
    class="video-player-box vjs-big-play-centered"
    ref="videoPlayer"
    :options="playerOptions"
    :playsinline="true"
    customEventName="customstatechangedeventname"
    @ended="onPlayerEnded($event)"
  >
  </video-player>
</template>

<script>
import 'video.js/dist/video-js.css'

import { videoPlayer } from 'vue-video-player'

export default {
  name: 'BaseVideo',
  components: {
    videoPlayer,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    img: {
      type: String,
      default: '',
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      playerOptions: {
        // videojs options
        muted: false,
        language: 'en',
        fluid: 'true',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: 'video/mp4',
            src: this.src,
          },
        ],
        poster: this.img,
        autoplay: this.autoplay,
      },
    }
  },
}
</script>

<style lang="scss">
.video-js .vjs-big-play-button .vjs-icon-placeholder::before,
.vjs-button > .vjs-icon-placeholder::before,
.video-js .vjs-modal-dialog,
.vjs-modal-dialog .vjs-modal-dialog-content {
  top: -2px !important;
}
</style>
