import firebase from 'firebase/app'
// const firebase = require('firebase/app');
// import "firebase/analytics";
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBY5FkbbNorr4saknefSm0pYioE7KackWw',
  authDomain: 'music-kindness.firebaseapp.com',
  databaseURL: 'https://music-kindness.firebaseio.com',
  projectId: 'music-kindness',
  storageBucket: 'gs://music-kindness.appspot.com/',
  messagingSenderId: '659254338603',
  appId: '1:659254338603:web:d99777d7f826e0a431766e',
}
// Initialize Firebase

firebase.initializeApp(firebaseConfig)

firebase.firestore()
if (location.hostname === 'localhost') {
  // firebase.auth().useEmulator('http://localhost:9099/');
  firebase.firestore().useEmulator('localhost', 8888)
}

// firebase.analytics();

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

export default firebase
