import Vue from 'vue'
import Vuex from 'vuex'

import checkout from './modules/checkout'
import workshop from './modules/workshop'
import admin from './modules/admin'
import showcase from './modules/showcase'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    checkout,
    workshop,
    admin,
    showcase,
  },
  strict: debug,
})
