<template>
  <div class="checkout-mobile">
    <CheckoutMobileHeader/>
    <BaseTimeline class="timeline" />
    <!-- <CheckoutMobileSchedule /> -->
    <v-expand-transition>
      <component
        :is="currentComponent()"
      ></component>
    </v-expand-transition>
    <CheckoutMobileNavigation />
  </div>
</template>

<script>
import BaseTimeline from '@/components/base/BaseTimeline'

import CheckoutMobileSchedule from '@/components/CheckoutMobileSchedule'
import CheckoutMobileSelectTime from '@/components/CheckoutMobileSelectTime'
import CheckoutInfo from '@/components/CheckoutInfo'
import CheckoutMobileBraintree from '@/components/CheckoutMobileBraintree'

import CheckoutMobileHeader from '@/components/CheckoutMobileHeader'
import CheckoutMobileNavigation from '@/components/CheckoutMobileNavigation'
import { mapState } from 'vuex'
export default {
  name: 'CheckoutMobile',
  components: {
    CheckoutMobileHeader,
    BaseTimeline,
    CheckoutMobileSchedule,
    CheckoutInfo,
    CheckoutMobileBraintree,
    CheckoutMobileNavigation,
    CheckoutMobileSelectTime,
  },
  computed: mapState({
    // arrow functions can make the code very succinct!
    title: state => state.checkout.selectedPackage,
    step: state => state.checkout.currentStep,
    date: state => state.checkout.date,
    time: state => state.checkout.time,

    // passing the string value 'count' is same as `state => state.count`
    // countAlias: 'count',

    // to access local state with `this`, a normal function must be used
    // countPlusLocalState (state) {
    //   return state.count + this.localCount
    // },
  }),
  methods: {
    currentComponent () {
      const steps = ['',
        'CheckoutMobileSchedule',
        'CheckoutMobileSelectTime',
        'CheckoutInfo',
        'CheckoutMobileBraintree',
      ]
      return steps[this.step]
    },
  },
  mounted () {
    console.log(this.step)
  },
  watch: {
    step (newValue, oldValue) {
      console.log('Step changed - Old: ' + oldValue + ' New: ' + newValue)
    },
  },
}
</script>

<style lang="scss" scoped>
//transitions
.timeline{
  margin-bottom: 40px;
}
</style>
