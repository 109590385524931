<template>
  <div>
    <div v-for="time in times" v-bind:key="time.index">
      <CheckoutTimeButton
        :time="time.time"
        v-on:select="updateSelected(time.index)"
        :selected="time.index == selectedTime"
        v-on:confirm="sendConfirm"
        :width="width"
      />
    </div>
  </div>
</template>

<script>
import CheckoutTimeButton from '@/components/CheckoutTimeButton'
export default {
  components: {
    CheckoutTimeButton,
  },
  data () {
    return {
      times: [],
      selectedTime: null,
    }
  },
  props: {
    width: {
      type: String,
      default: '100%',
    },
  },
  mounted () {
    for (let index = 0; index < 16; index++) {
      var hourMilitary = 9 + Math.floor(index / 2)
      var hour =
        Math.floor((9 + index / 2).toString()) % 12
          ? Math.floor((9 + index / 2).toString()) % 12
          : '12'
      var minute = index % 2 ? '30' : '00'
      var ampm = hourMilitary < 12 ? 'AM' : 'PM'
      this.times.push({
        index: index,
        time: hour + ':' + minute + ampm,
        selected: true,
      })
    }
  },
  methods: {
    updateSelected (i) {
      this.selectedTime = i
    },
    sendConfirm () {
      this.$store.dispatch('checkout/setTime', this.times[this.selectedTime].time).then(() => {
        if (this.$store.state.checkout.time) {
          this.$store.dispatch('checkout/incrementStep')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
