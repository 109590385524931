<template>
  <div v-if="step !==4" class="checkout-mobile-navigation">
    <h4 class="nav-title">selected</h4>
    <div class="button-wrapper">
        <v-btn color="white" class="package-title">{{ step }} : {{ title }} Package</v-btn>
        <v-btn class="next" :disabled="!canProgress()" color="primary" v-on:click="validate">Next</v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'CheckoutMobileNavigation',
  methods: {
    validate () {
      switch (this.step) {
        case 1:
          this.$store.dispatch('checkout/incrementStep')
          break
        case 2:
          this.$store.dispatch('checkout/incrementStep')
          break
        case 3:
          // trigger validation
          console.log('here')
          this.$store.dispatch('checkout/setTriggerValidation', true)
          this.$nextTick(() => {
            setTimeout(() => {
              console.log(this.valid)
              if (this.valid) {
                console.log('and here')
                this.$store.dispatch('checkout/incrementStep')
              }
            }, 500)
          })
          break
        default:
          break
      }
    },
    canProgress () {
      let theReturn
      switch (this.step) {
        case 1:
          theReturn = !!(this.date)
          break
        case 2:
          console.log(this.time)
          theReturn = !!(this.time)
          break
        case 3:
          theReturn = this.valid
          break
        case 4:
          theReturn = true
          break

        default:
          break
      }
      return theReturn
    },
  },
  computed: mapState({
    // arrow functions can make the code very succinct!
    title: state => state.checkout.selectedPackage,
    step: state => state.checkout.currentStep,
    date: state => state.checkout.date,
    time: state => state.checkout.time,
    valid: state => state.checkout.formValid,

    // passing the string value 'count' is same as `state => state.count`
    // countAlias: 'count',

    // to access local state with `this`, a normal function must be used
    // countPlusLocalState (state) {
    //   return state.count + this.localCount
    // },
  }),
}
</script>

<style lang="scss" scoped>
.checkout-mobile-navigation {
  position: fixed;
  width: 100vw;
  background-color: #F8F8F8;
  bottom: 0;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  z-index: 2;
  .nav-title {
    font-size: 1em;
    text-align: center;
    margin: 10px;
  }
  .button-wrapper{
    display: flex;
    width: 290px;
    margin: auto;
    justify-content: center;
    margin-bottom: 30px;
    .package-title{
        flex-grow: 3;
    }
    .next{
        margin-left: 8px;
    }
  }
}
</style>
