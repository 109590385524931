<template>
  <v-app id="inspire">
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'DefaultLogin',
  data: () => ({
    drawer: null,
  }),
}
</script>

<style lang="scss">
.v-toolbar--prominent .v-toolbar__content {
  align-items: center !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
