<template>
  <div class="checkout-mobile-braintree">
    <div class="payment-text-wrapper">
      <h2>Payment</h2>
      <!-- <br/> -->
      <!-- <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit exercitationem facere perspiciatis quod sequi commodi.</p> -->
    </div>
    <CheckoutBraintree padding="0 40px 20px 40px"/>
  </div>
</template>

<script>
import CheckoutBraintree from '@/components/CheckoutBraintree'
export default {
  components: {
    CheckoutBraintree,
  },
}
</script>

<style lang="scss" scoped>
.payment-text-wrapper {
  margin: 20px 46px 0 46px;
}
</style>
