<template>
  <div class="packages-inner-mobile">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="(card, index) in cards" v-bind:key="index">
        <div>
          <HomePackagesCard :info="card" />
        </div>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import HomePackagesCard from '@/components/HomePackagesCard.vue'

export default {
  name: 'HomePackagesMobile',
  title: 'Pagination / Dynamic bullets',
  components: {
    Swiper,
    SwiperSlide,
    HomePackagesCard,
  },
  props: {
    cards: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data () {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        initialSlide: 1,
        centeredSlides: true,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  mounted () {
    console.log(this.cards)
  },
}
</script>
<style lang="scss">
.packages-inner-mobile {
  .card {
    width: 100%;
  }
  .button {
    width: calc(100% - 72px);
  }
  .swiper-wrapper {
    align-items: flex-end;
  }
}
</style>

<style lang="scss" scoped>
.swiper {
  width: 100vw;

  .packages-inner-mobile {
    margin: 0;
  }

  .swiper-slide {
    width: 88%;
    max-width: 650px;
  }
}
</style>
