<template>
  <div class="checkout-mobile-header">
    <div class="icon pa-2"><v-img class="" src="@/assets/icon.svg" width="30"></v-img></div>
    <div class="checkout-header-inner">
      <div class="pa-3" v-on:click="back">Back</div>
      <div>
        <v-icon v-on:click="exit" class="pa-3" style="">mdi-close</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutMobileHeader',
  methods: {
    back () {
      this.$store.dispatch('checkout/decrementStep')
    },
    exit () {
      this.$store.dispatch('checkout/setIsMobileCheckoutOpen', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.checkout-mobile-header {
  position: fixed;
  width: 100vw;
  background-color: #f8f8f8;
  top: 0;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  z-index: 20;
  .icon{
    position: absolute;
    top:0;
    left:50%;
    transform: translateX(-50%);
  }
  .checkout-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
