<template>
  <div class="checkout-info">
    <div class="info-text-wrapper">
      <h2>Your Info</h2>
      <!-- <br/> -->
      <!-- <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit exercitationem facere perspiciatis quod sequi commodi.</p> -->
    </div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        :value="organizerName"
        v-on:change="updateOrganizerName($event)"
        :counter="30"
        :rules="organizerNameRules"
        label="Organizer's Name (that's you!)"
        required
      ></v-text-field>
      <v-text-field
        :value="organizerEmail"
        v-on:change="updateOrganizerEmail($event)"
        :rules="organizerEmailRules"
        label="Organizer's E-mail"
        required
      ></v-text-field>
      <!-- <v-text-field
        :value="groupName"
        v-on:change="updateGroupName($event)"
        :counter="30"
        :rules="GroupNameRules"
        label="...Group Name (NCL Chapter and Class)"
        required
      ></v-text-field> -->
      <v-select
        v-on:change="updateGroupName($event)"
        :items="items"
        label="NCL Chapter"
        persistent-hint
        return-object
        single-line
      ></v-select>
      <v-select
        v-on:change="updateGroupGrade($event)"
        :items="['7th', '8th', '9th', '10th', '11th', '12th']"
        item-text="state"
        item-value="abbr"
        label="NCL Grade Level"
        persistent-hint
        return-object
        single-line
      ></v-select>

      <!-- <v-select
      v-model="select"
      :items="items"
      :rules="[v => !!v || 'Item is required']"
      label="Item"
      required
    ></v-select> -->

      <v-checkbox
        :value="agreed"
        v-on:change="updateAgreed($event)"
        :rules="[(v) => !!v || 'You must agree to continue!']"
        label="I agree to the terms and conditions."
        required
      ></v-checkbox>

      <v-btn v-if="$vuetify.breakpoint.mdAndUp" right :disabled="!valid" color="primary" style="float:right;" @click="validate">
        Next
      </v-btn>

    <!-- <v-btn color="error" class="mr-4" @click="reset"> Reset Form </v-btn>

    <v-btn color="warning" @click="resetValidation"> Reset Validation </v-btn> -->
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import chapters from '@/json/chapters.json'
export default {
  data: () => ({
    items: chapters,
    valid: true,
    organizerNameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.length <= 30) || 'Name must be less than 30 characters',
    ],
    organizerEmailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    GroupNameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.length <= 30) || 'Name must be less than 30 characters',
    ],
    select: null,
    checkbox: false,
  }),
  methods: {
    updateOrganizerName ($event) {
      this.$store.dispatch('checkout/setOrganizerName', $event)
    },
    updateOrganizerEmail ($event) {
      this.$store.dispatch('checkout/setOrganizerEmail', $event)
    },
    updateGroupName ($event) {
      this.$store.dispatch('checkout/setGroupName', $event)
    },
    updateGroupGrade ($event) {
      this.$store.dispatch('checkout/setGroupGrade', $event)
    },
    updateAgreed ($event) {
      this.$store.dispatch('checkout/setAgreed', $event)
    },
    validate () {
      this.$refs.form.validate()
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.valid) {
            this.$store.dispatch('checkout/incrementStep')
          }
        }, 500)
      })
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
  },
  computed: mapState({
    // title: state => state.checkout.selectedPackage,
    // step: state => state.checkout.currentStep,
    // date: state => state.checkout.date,
    // time: state => state.checkout.time,
    organizerName: state => state.checkout.organizerName,
    organizerEmail: state => state.checkout.organizerEmail,
    groupName: state => state.checkout.groupName,
    groupGrade: state => state.checkout.groupGrade,
    agreed: state => state.checkout.agreed,
    validationFlag: state => state.checkout.validationFlag,
  }),
  mounted () {
    console.log(this.items)
  },
  watch: {
    valid (newValue) {
      console.log('changing')
      this.$store.dispatch('checkout/setFormValid', newValue)
    },
    validationFlag (newValue) {
      if (newValue) {
        this.$refs.form.validate()
      }
    },
    organizerName (newValue) {
      console.log(newValue)
    },
  },
  // mounted () {
  //   if (this.validateTrigger) {
  //     console.log('validate')
  //   } else {
  //     console.log('don\'t validate')
  //   }
  //   // this.$refs.form.validate()
  //   // validate
  //   // if valid emit
  // }
}
</script>

<style lang="scss" scoped>
.checkout-info {
  margin: 20px 46px;
}
</style>
