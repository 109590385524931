<template>
  <div class="packages-inner">
    <div v-for="(card, i) in cards" v-bind:key="card.index" class="package">
      <HomePackagesCard
        class="the-card"
        :class="'card-'+i"
        style="tranform:tranlateY(-20px);"
        :info="card"
        :isHovered="hovered"
        v-on:hoveredOn="buttonHovered = true"
        v-on:hoveredOff="buttonHovered = false"
      />
    </div>
  </div>
</template>

<script>
import HomePackagesCard from '@/components/HomePackagesCard'

export default {
  name: 'HomePackagesDesktop',
  props: {
    cards: Array,
    hovered: Boolean,
  },
  components: {
    HomePackagesCard,
  },
  mounted () {
    var tl = this.$gsap.timeline({
    // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: '.packages-inner',
        start: 'center bottom',
        end: 'top 100px',
        scrub: 1,
      },
    })
    tl.fromTo('.card-0', { y: 100 }, { y: 0, duration: 3, ease: 'power2.out' })
    tl.fromTo('.card-1', { y: 200 }, { y: 0, duration: 3, ease: 'power2.out' }, '^=3')
    tl.fromTo('.card-2', { y: 100 }, { y: 0, duration: 3, ease: 'power2.out' }, '^=6')
  },
}
</script>

<style lang="scss" scoped>
.package{
  transition: all .3s ease-in-out;
  &:hover{
    // transform: scale(1.03);
  }
}
</style>
