<template>
  <div class="braintree-wrapper" :style="{ width: width, padding: padding }">
    <!-- <h3 class="center">Checkout</h3> -->
    <div v-if="!$vuetify.breakpoint.mdAndUp">
      <h3>Summary</h3>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>Name:</td>
              <td>{{organizerName}}</td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>{{organizerEmail}}</td>
            </tr>
            <tr>
              <td>NCL Chapter:</td>
              <td>{{groupName}}</td>
            </tr>
            <tr>
              <td>NCL Grade:</td>
              <td>{{groupGrade}}</td>
            </tr>
            <tr>
              <td>Package:</td>
              <td>{{selectedPackage}}</td>
            </tr>
            <tr>
              <td>Amount:</td>
              <td>{{subtotal}}</td>
            </tr>
            <tr>
              <td>Tax:</td>
              <td>{{taxString}}</td>
            </tr>
            <tr>
              <td>Total:</td>
              <td>{{total}}</td>
            </tr>
            <tr>
              <td>Date:</td>
              <td>{{readableDate}}</td>
            </tr>
            <tr>
              <td>Time:</td>
              <td>{{selectedTime}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <v-braintree
      v-if="braintree"
      :authorization="token"
      btnText="Purchase"
      btnClass="braintree-continue"
      :card="{
        cardholderName: {
          required: true,
        },
      }"
      @success="onSuccess"
      @error="onError"
      @loadFail="onLoadFail"
    ></v-braintree>
    <div v-if="congrats" class="mt-4">
      <h2 class="">Thank you for your purchase!</h2>
      <p>Look for an email from us with further instructions!</p>
    </div>
    <div v-if="error" class="mt-4">
      <h2 class="">Oops, something went wrong!</h2>
      <p>Please try your purchase again, if the problem persists please email us at info@kindmusic.org</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Braintree',
  data () {
    return {
      token: '',
      braintree: false,
      congrats: false,
      error: false,
    }
  },
  props: {
    width: {
      type: String,
      default: '100%',
    },
    padding: {
      type: String,
      default: '40px',
    },
  },
  methods: {
    showCongratsMessage () {
      this.braintree = false
      this.congrats = true
      // console.log('Show congrats message')
    },
    showErrorMessage () {
      this.braintree = false
      this.error = true
    },
    onLoadFail (instance) {
      console.log(instance)
    },
    onSuccess (payload) {
      const nonce = payload.nonce
      const hours = (this.time.slice(this.time.length - 2, this.time.length) === 'AM') ? this.time.slice(0, this.time.indexOf(':')) : (parseInt(this.time.slice(0, this.time.indexOf(':'))) + 12)
      const minutes = this.time.slice(this.time.indexOf(':') + 1, this.time.indexOf(':') + 3)
      const date = new Date(this.date + 'T' + hours + ':' + minutes + ':00')

      const data = {
        organizerName: this.organizerName,
        organizerEmail: this.organizerEmail,
        groupName: this.groupName,
        groupGrade: this.groupGrade,
        date: date.getTime(),
        package: this.selectedPackage,
        nonce: nonce,
        ncl: true,
      }

      this.$axios
        .post('/checkout', data)
        .then((response) => {
          if (response.data) {
            this.showCongratsMessage()
          } else {
            this.showErrorMessage()
          }
        })
        .catch((error) => {
          console.log(error)
          this.showErrorMessage()
          return error
        })
    },
    onError (error) {
      const message = error.message
      console.log('Vue Braintree failed')
      console.log(message)
      this.showErrorMessage()
      // Show error message
    },
    getReadableDay (day) {
      switch (day) {
        case 1:
        case 21:
        case 31:
          return day + 'st'
        case 2:
        case 22:
          return day + 'nd'
        case 3:
        case 23:
          return day + 'rd'
        default:
          return day + 'th'
      }
    },
  },
  computed: {
    selectedPackage () { return this.$store.state.checkout.selectedPackage },
    date () { return this.$store.state.checkout.date },
    time () { return this.$store.state.checkout.time },
    organizerName () { return this.$store.state.checkout.organizerName },
    organizerEmail () { return this.$store.state.checkout.organizerEmail },
    groupName () { return this.$store.state.checkout.groupName },
    groupGrade () { return this.$store.state.checkout.groupGrade },
    tokenString () { return JSON.stringify(this.token) },
    // amount () { return { Bronze: '$299.00', Silver: '$749.00', Gold: '$999.00' }[this.selectedPackage] },
    selectedTime () { return this.$store.state.checkout.time },
    taxRate () { return this.$store.state.checkout.taxRate },
    taxString () { return this.$store.getters['checkout/getTaxString'] },
    subtotal () { return this.$store.getters['checkout/getSubtotalString'] },
    total () { return this.$store.getters['checkout/getTotalString'] },
    readableDate () {
      const date = new Date(this.date)

      // Readable Month
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ]
      const month = monthNames[date.getUTCMonth()]

      // Readable Day
      const day = this.getReadableDay(date.getUTCDate())

      return month + ' ' + day
    },
  },
  mounted () {
    this.$axios
      .get('/clientToken')
      .then((response) => {
        this.token = response.data
        this.braintree = true
      })
      .catch((error) => {
        // handle error
        console.log(error)
        return error
      })
  },
}
</script>

<style lang="scss">
.braintree-wrapper {
  .braintree-continue {
    background-color: #545bd6;
    // font-size: calc(8px + 0.5vw);
    border: 1px solid #545bd6;
    padding: 6px 50px;
    border-radius: 5px;
    color: white;
    float: right;
  }
}

</style>

<style lang="scss" scoped>
h3{
  font-size: 20px;
  color: black;
  font-weight: 500;
  line-height: 1;
  padding: 50px 0 28px 0;
}
p{
  margin-top: 16px;
}
</style>
