<template>
  <div class="how-it-works-blurb" :style="cssVars">
    <div v-if="isReversed" :class="leftTargetName" class="left-image">
      <slot></slot>
    </div>
    <div :class=" (!isReversed) ? leftTargetName : rightTargetName ">
      <div class="blurb-head">
        <v-btn
          class="ml-2 mr-5 float-left"
          fab
          dark
          small
          depressed
          color="primary"
        >
          {{ number }}
        </v-btn>
        <div class="blurb-title">
          {{ title }}
        </div>
      </div>
      <div class="blurb-text">
        <p v-if="!isHtml">{{ blurb }}</p>
        <span v-else v-html="blurb"></span>
      </div>
    </div>
    <div v-if="!isReversed" :class="rightTargetName" class="right-image">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    blurb: {
      type: String,
      default: '',
    },
    leftPercent: {
      type: Number,
      default: 50,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    isHtml: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    leftTargetName () { return 'lefttarget' + this.number },
    rightTargetName () { return 'righttarget' + this.number },
    cssVars () {
      return {
        '--template-columns':
          this.leftPercent + 'fr ' + (100 - this.leftPercent) + 'fr',
      }
    },
    isReversed () {
      return this.reverse && this.$vuetify.breakpoint.mdAndUp
    },
  },
  mounted () {
    // target left
    var tl = this.$gsap.timeline({
    // yes, we can add it to an entire timeline!
      scrollTrigger: {
        trigger: '.' + this.leftTargetName,
        start: 'center bottom',
        end: 'top 100px',
      },
    })

    tl.to('.' + this.leftTargetName, {
      x: 0,
      opacity: 1,
      duration: 1,
      ease: 'power2.out',
    })

    // target right
    tl.to('.' + this.rightTargetName, {
      x: 0,
      opacity: 1,
      duration: 2,
      ease: 'power2.out',
    }, '-=0.75')
  },
}
</script>

<style lang="scss" scoped>
.how-it-works-blurb {
  display: grid;
  grid-template-columns: var(--template-columns);
  grid-gap: 5vw;
  text-align: left;
  padding-bottom: 5vw;
  font-size: 1.2em;
  >*:first-child{
    transform: translateX(-300px);
    opacity: 0;
  }
  >*:nth-child(2){
    transform: translateX(300px);
    opacity: 0;
  }
  .blurb-head {
    display: flex;
    align-items: center;
    .blurb-title {
      font-size: calc(16px + 0.4vw);
      font-weight: 800;
      color: #30456D;
    }
  }
  .blurb-text {
    line-height: 1.7;
    padding: 30px 8px;
    font-weight: 500;
    color: #30456D;
  }
}
.right-image > img{
  float: right;
}
@media only screen and (max-width: 600px) {
  .how-it-works-blurb {
    grid-template-columns: 1fr;
  }
}
.float-left {
  float: left;
}
</style>
