import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: '#DAF3F9',
        secondary: '#fe4a49',
        accent: '#1fc94f',
        error: '#b71c1c',
      },
      light: {
        primary: '#FFB000',
        secondary: '#fe4a49',
        accent: '#1fc94f',
        error: '#b71c1c',
      },
    },
  },
})
