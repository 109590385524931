// Logout button in header
// Workshop move "continue/back" into separate div
//

import Vue from 'vue'
import App from './App.vue'

import store from './store'

import firebase from './firebaseinit'

import axios from 'axios'

import braintree from 'vue-braintree'

import Vuelidate from 'vuelidate'

import VueKinesis from 'vue-kinesis'

import VueWaveSurfer from 'vue-wave-surfer'

import router from './router'

import vuetify from './plugins/vuetify'

import AudioVisual from 'vue-audio-visual'

import { gsap } from 'gsap'

import ScrollTrigger from 'gsap/ScrollTrigger'

import VueMeta from 'vue-meta'

import VueShareSocial from 'vue-share-social'

Vue.use(VueShareSocial)

Vue.use(VueMeta)

gsap.registerPlugin(ScrollTrigger)

Vue.use(AudioVisual)

Vue.prototype.$firebase = firebase
Vue.prototype.$gsap = gsap
Vue.prototype.$axios = axios
Vue.use(braintree)
Vue.use(Vuelidate)
Vue.use(VueKinesis)
Vue.use(VueWaveSurfer)

var VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
